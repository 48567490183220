import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgOptimizedImage } from '@angular/common';

import { DynamicPageImageContent } from '../../services/dynamic-page/dynamic-page.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollerHandlerComponent } from '../bibliography/scroller-handler/scroller-handler.component';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    ScrollerHandlerComponent,
  ],
})
export class ImageComponent {
  @Input({ required: true }) protected readonly image: DynamicPageImageContent;

  @HostBinding('class.center') protected center = true;
  @HostBinding('class.loaded') protected loaded = false;
  @HostBinding('class.error') protected error = false;
}
