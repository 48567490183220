<figure class="image-wrapper">
  <img [ngSrc]="image.src"
       [alt]="image.alt || 'wystąpił błąd przy pobieraniu obrazka'"
       width="auto" height="auto"
       class="image-element image-box"
       (load)="loaded = true"
       (error)="error = true"
  >

  @if (!loaded && !error) {
    <mat-spinner diameter="70"></mat-spinner>
  }

  @if (error) {
    <div class="error-container">
      <mat-icon svgIcon="icons:error"></mat-icon>
      {{ image.alt || 'Wystąpił błąd przy pobieraniu obrazka.' }}
    </div>
  }

  <figcaption>
    @if (image.bibliographyNo) {
      <app-scroll-handler [index]="image.bibliographyNo"></app-scroll-handler>
    }{{ image.caption }}
  </figcaption>
</figure>
